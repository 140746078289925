<template>
  <InputTemplate
    v-bind="$props"
    :validity="validity"
    :is-disabled="isDisabled"
    :hide-right-picto="!validationPictoIsDisplayed"
    :focus="focus"
  >
    <template #left-picto>
      <slot name="left-picto" />
    </template>
    <template #right-pcto>
      <slot name="right-picto" />
    </template>
    <template #err-message>
      <slot name="err-message" />
    </template>
    <template #label>
      <slot v-if="$slots.label || !!label" name="label">{{ label }}</slot>
    </template>
    <template #input-left><slot name="input-left" /></template>
    <template #input="{ uid }">
      <input
        v-bind="$attrs"
        :id="inputId || uid"
        class="textInput focus:!tw-border-2"
        :class="{
          disabled,
          softDisabled,
          hasPicto,
          hasRightPadding,
          '!tw-border-black': focus && pValidity,
          '!tw-border-error': !pValidity,
        }"
        :type="type"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="isDisabled"
        :maxLength="maxLength"
        :data-test-id="dataTestId"
        @keyup="handleKeyup"
        @input="handleInputUpdate"
        @blur="handleBlur"
        @focus="handleFocus"
      />
    </template>
    <template #input-right><slot name="input-right" /></template>
  </InputTemplate>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import InputTemplate from './template/input.vue'
import logicMixin from './template/logicMixin'

export default defineComponent({
  components: {
    InputTemplate,
  },
  mixins: [logicMixin],
  props: {
    inputId: {
      type: String,
      required: false,
      default: '',
    },
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    dataTestId: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
  },
  emits: {
    'update:modelValue': (payload: string) => true,
  },
  computed: {
    validationPictoIsDisplayed(): boolean {
      const shouldConsiderShowingRightPicto =
        this.modelValue &&
        this.modelValue.length > 0 &&
        !this.focus &&
        !this.isValidating

      if (!shouldConsiderShowingRightPicto) return false

      if (this.pValidity !== undefined) return this.pValidity

      return this.validity && this.pApplyValidity && !this.hideRightPicto
    },
    hasRightPadding(): boolean {
      return (
        ((this.validity && this.modelValue && this.modelValue.length > 0) ||
          this.$slots['right-picto'] !== undefined) &&
        !this.hideRightPicto
      )
    },
  },
  methods: {
    handleInputUpdate($event: Event) {
      const { target } = $event

      if (!target) return

      const { value } = target as HTMLInputElement
      this.$emit('update:modelValue', value)
    },
  },
})
</script>

<style lang="scss" scoped src="./template/style.scss"></style>
